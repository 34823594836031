<template>
  <div class="decile-chart" ref="barChart" v-on:click="clickOutsideDropdown($event)">

    <div class="decile-chart__content">
      <div class="decile-chart__title">
        <div class="dropdown-categories-wrapper title-occupation" v-on:click="toggleChartCategories($event)">
          {{ selectedDecileTitle }}
          <div v-if="isCategoriesShown" class="dropdown-categories" ref="dropdown">
            <div v-for="title in decilesTitles" :key="title"
                 class="dropdown-category"
                 v-on:click="selectDecile(title)">
              {{ title }}
            </div>
          </div>
        </div>
        :
        <span>
        {{ decileChartData?.segment }}
        {{ translations['text-in-comparison-to'] }} {{ translations['text-danish-population'] }}</span>
      </div>

      <div class="decile-chart__legend-panel">
      <span class="decile-chart__legend">
        <span class="decile-chart__legend-point" :style="{ 'background-color': decileChartData?.color }"></span>
        <span class="decile-chart__legend-title"
              :style="{ 'color': decileChartData?.color }">
          {{ segment.length > 1 ? 'Type' : translations['text-group'] }}
          {{ decileChartData?.segment }}
        </span>
      </span>

        <span class="decile-chart__legend">
        <span class="decile-chart__legend-point" :style="{ 'background-color': dkDefaultColor }"></span>
        <span class="decile-chart__legend-title"
              :style="{ 'color': dkDefaultColor }">{{ translations['text-danish-population'] }}</span>
      </span>
      </div>

      <div class="options">
        <div v-for="option in decilesDataMapper[selectedDecileTitle]" :key="option.title" class="option">

        <span class="option__title"
              :class="{ 'percent-view-border': viewMode === 'percentage' }">
          <span :style="{color: decileChartData.color}">{{ option.title }}</span>
        </span>

          <div v-if="viewMode === 'percentage'"
               class="option__perc-results">
            <div class="line progress-line"
                 :style="{
              'width': option.percentSegmentWidth + '%',
              'background-color': decileChartData?.color }">
            </div>

            <div class="line progress-line"
                 v-on:mouseover="selectLine(option, 'dkPercent')"
                 v-on:mouseleave="selectLine(option, 'segmentPercent')"
                 :style="{
              'width': option.percentDkWidth + '%',
              'background-color': dkDefaultColor }">
            </div>

            <div class="percentage opacity"
                 :style="{'left':
                option.percentDkWidth >= option.percentSegmentWidth ? option.percentDkWidth + '%' : option.percentSegmentWidth + '%',
                color: optionsPercentMapper[option.title]?.color}">
              {{ optionsPercentMapper[option.title]?.percent }}%
            </div>
          </div>

          <div v-if="viewMode === 'index'"
               class="option__index-results">
            <div class="less"
                 :style="{borderRight: `4px solid ${dkDefaultColor}`}">
              <div class="less-line progress-line"
                   :style="{
                  'width': option.segmentIndex < 100 ? option.indexWidth + '%' : '0%',
                  'background-color': decileChartData.color}">
              </div>

              <span class="less-result opacity"
                    :style="{
                  color: decileChartData.color,
                  right: option.indexWidth + 4 + '%'}">
              {{ option.segmentIndex < 100 ? option.segmentIndexTitle : '' }}
            </span>
            </div>

            <div class="more">
              <div class="more-line progress-line"
                   :class="{'more-line-limit': option.segmentIndex > 199}"
                   :style="{
                  'width': option.segmentIndex >= 101 ? option.indexWidth + '%' : '0%',
                  'background-color': decileChartData.color
                  }">
              </div>

              <span v-if="option.segmentIndex >= 101" class="more-result opacity"
                    :style="{
                  'color': decileChartData.color,
                   left: option.indexWidth + 4 + '%'
                  }">
              {{ option.segmentIndex >= 101 ? option.segmentIndexTitle : '' }}
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="btn-panel">
      <BarChartSwitcher v-on:click="switchViewMode" v-bind:viewMode="viewMode"/>
      <CopyBtn v-bind:isProcessing="isProcessing"
               v-bind:translationPlaceholder="translations['text-downloading-the-copy']"
               @onCopy="onChartCopy"/>
    </div>

    <WatermarkSign/>
    <CopyRightsRef v-bind:copyRightRef="translations['text-source']"/>
  </div>
</template>


<script>
import CopyRightsRef from "@/shared/components/CopyRightsRef/CopyRightsRef";
import CopyBtn from "@/shared/components/CopyBtn/CopyBtn";
import WatermarkSign from "@/shared/components/WatermarkSign/WatermarkSign";
import BarChartSwitcher
  from '@/components/ConzoomDecile/components/BarChartSwitcherComponent/BarChartSwitcherComponent';
import {
  copyChart,
  getDecileData,
  getDecilesConfig,
  getChartTranslations, getLightenOrDarkenColorBy
} from "@/service";

export default {
  name: 'ConzoomDecileComponent',
  props: {
    api: String,
    token: String,
    lang: String,
    country: String,
    segment: String,
    color: String,
  },
  components: {CopyBtn, BarChartSwitcher, WatermarkSign, CopyRightsRef},
  async mounted() {
    this.setClickOutsideListener();
    // this.dkDefaultColor = getLightenOrDarkenColorBy(this.color, 50);
    const translation = await getChartTranslations(this.api, this.token, this.lang, 'conzoom-decile');
    this.translations = translation.reduce((acc, transl) => ({...acc, [transl.key]: transl.value}), {});
    this.setDecilesData();
  },
  beforeUnmount() {
    this.subscriptions.forEach((subscription) => clearTimeout(subscription));
  },
  methods: {
    async setDecilesData() {
      const decilesConfig = await getDecilesConfig(this.api, this.token, this.country);
      decilesConfig.variables.sort(
          (prev, next) => prev === decilesConfig.default_variable ? -1 : next === decilesConfig.default_variable ? 1 : 0
      );

      const detaRequests = decilesConfig.variables.map((variable) => getDecileData(this.api, this.token, variable, this.segment));

      Promise.all(detaRequests)
          .then((response) => response.map((decileData) => decileData.data.data[0]))
          .then((decilesData) => {
            this.decilesTitles = decilesData
                .map((data) => data.translations.find((translation) => translation.lang === this.lang).name)
            this.decileChartData = this._getPreparedDecilesData(this.segment, this.color, decilesData);
            this.selectedDecileTitle = this.decileChartData.deciles[0]?.title;
          })
          .then(() => {
            this._prepareSegmentsView();
            this._generateOptionsPercentMapper();
          })
    },
    _getPreparedDecilesData(segment, color, decilesData) {
      const getDeciles = (decilesData) => {
        return decilesData.map((data) => {
          return {
            title: data.translations.find((translation) => translation.lang === this.lang).name,
            data: data.conzoom_data.map((options) => {
              return {
                country_average: options.country_average,
                segment: options[this.segment.toLowerCase()],
                title: options.label.translations.find((trans) => trans.lang === this.lang).value
              };
            })
          }
        })
      };

      return {
        segment,
        color,
        deciles: getDeciles(decilesData),
      };
    },
    setClickOutsideListener() {
      this.selfEl = document.getElementsByTagName('conzoom-decile')[0];

      document.addEventListener('click', (event) => {
        if (this.isCategoriesShown && this.selfEl !== event.target) {
          this.isCategoriesShown = false;
        }
      });
    },
    onChartCopy() {
      this.isProcessing = true;
      copyChart(this.$refs.barChart, document.querySelector('conzoom-decile').shadowRoot.styleSheets)
          .then(() => this.showCopiedTooltip());
    },
    selectLine(line, linePart) {
      this.optionsPercentMapper[line.title] = {
        percent: line[linePart],
        color: linePart === 'dkPercent' ? this.dkDefaultColor : this.decileChartData.color
      }
    },
    switchViewMode() {
      this.viewMode = this.viewMode === 'percentage' ? 'index' : 'percentage';
    },
    showCopiedTooltip() {
      const timer = setTimeout(() => {
        this.isProcessing = false;
      }, 3000);

      this.subscriptions.push(timer);
    },
    _generateOptionsPercentMapper() {
      this.optionsPercentMapper = this.decilesDataMapper[this.selectedDecileTitle]
          .reduce((acc, next) => ({
            ...acc,
            [next.title]: {color: this.decileChartData?.color, percent: next.segmentPercent}
          }), {});
    },
    toggleChartCategories(event) {
      this.isCategoriesShown = !this.isCategoriesShown;
      event.stopPropagation();
    },
    clickOutsideDropdown(event) {
      const dropdown = document.querySelector('conzoom-decile').shadowRoot.querySelector('.dropdown-categories');

      if (this.isCategoriesShown && event.target !== dropdown) {
        this.isCategoriesShown = false;
      }
    },
    selectDecile(decileTitle) {
      this.selectedDecileTitle = decileTitle;
      this._generateOptionsPercentMapper();
    },
    _prepareSegmentsView() {
      const getPreparedDecileData = (data) => {
        let biggestSegmentValue = 0;
        let biggestDiffFromCountryAverageIndex = 0;
        let indexValueGraterThanCountryAverage = 0;
        let indexValueLessThanCountryAverage = 0;

        //find the biggest value/index in segment
        data.forEach((option) => {
          const biggestOptValue = +option.country_average > +option.segment ? +option.country_average : +option.segment;
          biggestSegmentValue = biggestSegmentValue > biggestOptValue ? biggestSegmentValue : biggestOptValue;

          const indexValue = +(option.segment / option.country_average * 100).toFixed(2);

          if (indexValue > 100) {
            indexValueGraterThanCountryAverage = indexValue > indexValueGraterThanCountryAverage ?
                indexValue : indexValueGraterThanCountryAverage;
          } else {
            indexValueLessThanCountryAverage = indexValue > indexValueLessThanCountryAverage ?
                indexValue : indexValueLessThanCountryAverage;
          }
        });

        return data.map((option) => {
          const indexValue = +(option.segment / option.country_average * 100).toFixed(2);
          let indexWidth = 0;
          
          if (indexValue > 200) {
            indexWidth = 100;
          } else {
            let zeroAlignedIndex = indexValue >= 100 ? indexValue - 100 : 100 - indexValue;
            indexWidth = +zeroAlignedIndex.toFixed(2)
          }

          return {
            title: option.title,
            country_average: option.country_average,
            dkPercent: Math.round(option.country_average * 100),
            segmentPercent: Math.round(option.segment * 100),
            segment: option.segment,
            percentSegmentWidth: Math.round(option.segment / biggestSegmentValue * 100),
            percentDkWidth: Math.round(option.country_average / biggestSegmentValue * 100),
            segmentIndex: +((option.segment / option.country_average) * 100).toFixed(1),
            segmentIndexTitle: indexValue >= 101 ? Math.ceil(indexValue) : Math.floor(indexValue),
            indexWidth: indexWidth,
          }
        });
      }

      this.decileChartData.deciles.forEach((decile) => this.decilesDataMapper[decile.title] = getPreparedDecileData(decile.data));
    }
  },
  data: () => {
    return {
      decileChartData: null,
      decilesTitles: [],
      viewMode: 'percentage',
      isCategoriesShown: false,
      isProcessing: false,
      subscriptions: [],
      dkDefaultColor: 'rgba(0, 0, 0, 0.2)',
      optionsPercentMapper: {},
      translations: {},
      decilesDataMapper: {},
      selectedDecileTitle: '',
    };
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
