<template>
  <div class="copy-button-wrapper">
    <span class="copy-button-hint" v-if="isProcessing">{{translationPlaceholder}}</span>

    <span :class="{ 'in-progress-btn': isProcessing }"
          class="copy-button" v-on:click="copyChart">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M16 0H15H5H4V1V2V3H5V2V1H6H14H15V2V10V11H14H13V12H14H15H16V11V1V0ZM0 16V4H12V16H0ZM1 15V5H11V15H1Z"
              fill="black"/>
      </svg>
    </span>
  </div>
</template>


<script>
export default {
  name: "CopyBtn",
  props: {
    isProcessing: Boolean,
    translationPlaceholder: String,
  },
  methods: {
    copyChart() {
      if (this.isProcessing) return;
      this.$emit('onCopy')
    }
  }
}
</script>
