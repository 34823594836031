import domtoimage from 'dom-to-image';
import axios from 'axios';

let revertingElementsMapper: any[] = [];

export const copyChart = async(copiedChartEl, styleSheet, isDomtoimage?) => {
    // for local test local download
    if (isDomtoimage) {
        return new Promise((resolve, reject) => {
            prepareChartForCopying(copiedChartEl);

            domtoimage.toPng(copiedChartEl)
            .then(function(dataUrl) {
                const link = document.createElement('a');
                link.download = 'pie-chart.png';
                link.href = dataUrl;
                link.click();
                revertChartToShowing(copiedChartEl);
                resolve('done');
            })
            .catch((err) => reject(err));
        });
    } else {
        prepareChartForCopying(copiedChartEl);

        const css = getCssFromStyleSheet(styleSheet);
        const payload = { html: copiedChartEl.outerHTML, css: css, ms_delay: 2000 };
        const stringlifiedPayload = JSON.stringify(payload);
        revertChartToShowing(copiedChartEl);

        const options = {
            auth: {
                username: 'bc354710-34d1-46e4-a199-24903bb09494',
                password: '15340ba7-d95b-4df0-9402-1049f0c56b69'
            },
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const postResponse = await axios.post('https://hcti.io/v1/image', stringlifiedPayload, options);
            const fileName: string = postResponse.data.url.split('/').pop() as string;
            const getResponse = await axios.get(postResponse.data.url, { responseType: 'blob' });
            const imageURL = URL.createObjectURL(getResponse.data);
            const link = document.createElement('a');
            link.href = imageURL;
            link.download = fileName;
            link.click();

            return 'done';

        } catch(error) {
            console.error(error);
            return error;
        }
    }
};

export const getTypeDistributionChartData = async (api: string, token: string, segment: string) => {
    const options = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            filter: `{"_and":[{"_and":[{"segment":{"_eq":"${segment.toUpperCase()}"}},{"status":{"_eq":"published"}}]}]}`,
            limit: `1`,
            fields: `segment,conzoom_types.segment,conzoom_types.color,conzoom_types.group_percent`,
        }
    };

    const data = await axios.get(`${api}/items/conzoom_groups`, options);
    return data.data;
};

export const generateTypeDistributionChart = (data) => {
    // set size of <svg> element
    data.el.setAttribute('width', 2 * data.radius);
    data.el.setAttribute('height', 2 * data.radius);

    // calculate sum of values
    let sum = 0;
    const radius = data.radius;
    for(let e = 0; e < data.segments.length; e++) {
        sum += data.segments[e].percent;
    }

    // generate proportional pie for all segments
    let startAngle = 0, endAngle = 0;

    for(let i = 0; i < data.segments.length; i++) {
        const element = data.segments[i];
        const angle = element.percent * 2 * Math.PI / sum;

        endAngle += angle;
        const svgLine = makeSVG('line', {
            x1: radius,
            y1: radius,
            x2: (Math.cos(endAngle) * radius + radius),
            y2: (Math.sin(endAngle) * radius + radius),
            stroke: element.color
        });
        data.el.appendChild(svgLine);
        const pathStr =
            'M ' + (radius) + ',' + (radius) + ' ' +
            'L ' + (Math.cos(startAngle) * radius + radius) + ',' +
            (Math.sin(startAngle) * radius + radius) + ' ' +
            'A ' + (radius) + ',' + (radius) +
            ' 0 ' + (angle < Math.PI ? '0' : '1') + ' 1 ' +
            (Math.cos(endAngle) * radius + radius) + ',' +
            (Math.sin(endAngle) * radius + radius) + ' ' +
            'Z';
        const svgPath = makeSVG('path', { d: pathStr, fill: element.color });
        data.el.appendChild(svgPath);

        startAngle += angle;
    }
};

export const generateDistributionChartLabels = (data) => {
    let anglesSum = 0;
    const labelCoordinates = [];

    for(let i = 0; i < data.length; i++) {
        const angleStart = anglesSum;
        anglesSum += data[i].percent * 3.6;
        const angleEnd = anglesSum;

        let halfPathAngle = (angleStart + angleEnd) / 2;

        if (data[i - 1] && data[i - 1].percent < 3 && data[i].percent < 3) {
            halfPathAngle = halfPathAngle + 3;
        }

        if (data[i + 1] && data[i + 1].percent < 3 && data[i].percent < 3) {
            halfPathAngle = halfPathAngle - 3;
        }

        const coordinates = {
            percent: data[i].percent,
            halfPathAngle: halfPathAngle,
            color: data[i].color
        };

        // @ts-ignore
        labelCoordinates.push(coordinates);
    }

    return labelCoordinates;
};

export const getDecilesConfig = async(api: string, token: string, country: string) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const params = {
        filter: `{"_and":[{"country":{"code":{"_eq":"${country}"}}}]}`,
        limit: `1`,
        fields: `default_variable,variables`,
    };

    const decilesRes = await axios.get(`${api}/items/conzoom_decile_config`, { headers, params });

    return decilesRes.data.data[0];
};

export const getDecileData = async(api, token, decile: string, segment: string) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const params = {
        fields: `id,conzoom_data.label.translations.lang,conzoom_data.label.translations.value,conzoom_data.label.translations.id,conzoom_data.country_average,conzoom_data.${segment.toLowerCase()},translations.lang,translations.name`,
        filter: `{"_and":[{"id":{"_eq":"${decile}"}}]}`,
    };

    return axios.get(`${api}/items/conzoom_varaibles`, { headers, params });
};

export const getCompassData = async(api: string, token: string, country: string, segment: string) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        params: {
            fields: `variable.id,variable.conzoom_data.${segment.toLowerCase()},variable.conzoom_data.label.translations.lang,variable.conzoom_data.label.translations.value`,
            filter: `{"_and":[{"country":{"code":{"_eq":"${country}"}}}]}`,
            limit: '1'
        }
    };

    const compassChartRes = await axios.get(`${api}/items/conzoom_galup_compass_config`, options);

    return compassChartRes.data;
};

export const getChartTranslations = async (api: string, token: string, lang: string, chartName: string) => {
    const options = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            fields: `key,value`,
            filter: `{"_and":[{"key":{"translation_configs":{"translation_configs_id":{"id":{"_eq":"${chartName}"}}}}},{"lang":{"code":{"_eq":"${lang}"}}}]}`,
        }
    };

    const translationsRes = await axios.get(`${api}/items/translations`, options);
    return translationsRes.data.data;
}

const getCssFromStyleSheet = (styleSheet) => {
    return [...styleSheet]
    .map(styleSheet => {
        try {
            return [...styleSheet.cssRules]
            .map(rule => rule.cssText)
            .join('');
        } catch(e) {
            console.log('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);
        }
    })
    .filter(Boolean)
    .join('\n');
};

export const getLightenOrDarkenColorBy = (segmentColor, segmentValue) => {
    const hex = segmentColor.toString().replace(/[^0-9a-f]/gi, "");
    const color = parseInt(hex, 16);

    let r = (color >> 16) + segmentValue,
        g = (color & 0x0000ff) + segmentValue,
        b = ((color >> 8) & 0x00ff) + segmentValue;

    r = r <= 255 ? r : 255;
    g = g <= 255 ? g : 255;
    b = b <= 255 ? b : 255;

    const resultColor = (g | (b << 8) | (r << 16)).toString(16);
    return `#${resultColor}`;
}

export const colorLuminance = (hex, lum) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00"+c).substr(c.length);
    }

    return rgb;
}

// SVG Maker - to draw SVG by script
function makeSVG(tag, attrs) {
    const el = document.createElementNS('http://www.w3.org/2000/svg', tag);
    for(const k in attrs)
        el.setAttribute(k, attrs[k]);
    return el;
}

function prepareChartForCopying(copiedChartEl) {
    const watermark = copiedChartEl.getElementsByClassName('watermark')[0];
    const btnPanel = copiedChartEl.getElementsByClassName('btn-panel')[0];
    const copyRightsRef = copiedChartEl.getElementsByClassName('copy-rights-ref')[0];
    const opacityTarget = copiedChartEl.getElementsByClassName('opacity')[0];
    const progressLineTarget = copiedChartEl.getElementsByClassName('progress-line')[0];
    const titleOccupationTarget = copiedChartEl.getElementsByClassName('title-occupation')[0];

    copyRightsRef.removeAttribute('hidden');
    watermark.removeAttribute('hidden');
    btnPanel.classList.add('hidden')

    if (opacityTarget) {
        opacityTarget.classList.remove('opacity');
    }

    if (progressLineTarget) {
        opacityTarget.classList.remove('progress-line');
    }

    if (titleOccupationTarget) {
        revertingElementsMapper.push({ el: titleOccupationTarget, class: 'title-occupation' });
        titleOccupationTarget.classList.remove('title-occupation');
    }
}

function revertChartToShowing(copiedChartEl) {
    const watermark = copiedChartEl.getElementsByClassName('watermark')[0];
    const btnPanel = copiedChartEl.getElementsByClassName('btn-panel')[0];
    const copyRightsRef = copiedChartEl.getElementsByClassName('copy-rights-ref')[0];

    watermark.setAttribute('hidden', true);
    copyRightsRef.setAttribute('hidden', true);
    btnPanel.classList.remove('hidden');

    const revertingElems = revertingElementsMapper.filter((revertElem) => copiedChartEl.contains(revertElem.el));

    if (revertingElems.length) {
        revertingElems.forEach((revertElem) => revertElem.el.classList.add(revertElem.class));
        revertingElementsMapper = revertingElementsMapper.filter((revertElem) => !copiedChartEl.contains(revertElem.el));
    }
}
