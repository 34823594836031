

import CopyBtn from '@/shared/components/CopyBtn/CopyBtn.vue';

export default {
  name: 'ConzoomFactChartComponent',
  props: {
    value: String,
    color: String,
    title: String,
  },
  components: {CopyBtn},
  mounted() {
    this.subscription = setInterval(() => {
      this.chartValue += 1;
      if (+this.value === this.chartValue) {
        clearInterval(this.subscription);
      }
    }, 10);
  },
  computed: {},
  methods: {},
  data: () => {
    return {
      chartValue: 0,
      subscription: null,
    };
  }
};
