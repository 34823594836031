import { defineCustomElement } from 'vue';
import ConzoomTypeDistributionComponent from '@/components/ConzoomTypeDistributionComponent/ConzoomTypeDistributionComponent.ce.vue';
import ConzoomFactChartComponent from '@/components/ConzoomFactChartComponent/ConzoomFactChartComponent.ce.vue';
import ConzoomGalupCompassComponent from '@/components/ConzoomGalupCompassComponent/ConzoomGalupCompassComponent.ce.vue';
import ConzoomDecileComponent from '@/components/ConzoomDecile/ConzoomDecileComponent.ce.vue';
import ConzoomLanguageSwitcher from '@/components/ConzoomLanguageSwitcher/ConzoomLanguageSwitcher.ce.vue';

const PieChartCustomElement = defineCustomElement(ConzoomTypeDistributionComponent);
customElements.define('conzoom-type-distribution', PieChartCustomElement);

const ConzoomFactChartCustomElement = defineCustomElement(ConzoomFactChartComponent);
customElements.define('conzoom-fact', ConzoomFactChartCustomElement);

const GalupCompassCustomElement = defineCustomElement(ConzoomGalupCompassComponent);
customElements.define('conzoom-galup-compass', GalupCompassCustomElement);

const ConzoomDecileCustomElement = defineCustomElement(ConzoomDecileComponent);
customElements.define('conzoom-decile', ConzoomDecileCustomElement);

const LanguageSwitcherCustomElement = defineCustomElement(ConzoomLanguageSwitcher);
customElements.define('conzoom-language-switcher', LanguageSwitcherCustomElement);
