<template xmlns:v-bind>
  <div class="chart-loader">
    <svg viewBox="0 0 32 32">
      <circle r="16" cx="16" cy="16"
              v-bind:style="{ 'stroke-dasharray': `${100 - this.chartLoaderValue}, 100`}"
              v-bind:stroke="'#F5F5F5'"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "DistributionChartLoader",
  props: {
    chartLoaderValue: Number,
  },
}
</script>
