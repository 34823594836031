<template xmlns:v-bind>
  <div class="type-distribution-chart" ref="chartPie">
    <div class="type-distribution-chart__content">
      <div class="type-distribution-chart__title">{{ translations['widget-title-conzoom-type-distribution'] }}</div>

      <div class="type-distribution-chart__legend-panel">
      <span v-for="item in distChartData" :key="item.title"
            class="type-distribution-chart__legend">
        <span class="type-distribution-chart__legend-point"
              :style="{ 'background-color': item.color }"></span>
        <span class="type-distribution-chart__legend-title"
              :style="{ 'color': item.color }">{{ item.title }}</span>
      </span>
      </div>

      <div class="type-distribution-chart-wrapper"
           :style="{height: chartOptions.radius * 2 + 'px'}">
        <div class="chart-label__wrapper"
             :style="{ 'transform': `rotate(${coord.halfPathAngle}deg)` }"
             v-for="coord in labelCoordinates" :key="coord.percent">
          <div class="chart-label opacity"
               :style="{
             'transform': `rotate(-${coord.halfPathAngle}deg)`,
             'color': coord.color}">{{ coord.percent }}%
          </div>
        </div>

        <DistributionChartLoader v-bind:chart-loader-value="chartLoaderValue"/>

        <svg class="chart" ref="chart"></svg>
      </div>
    </div>

    <span class="btn-panel">
      <CopyBtn v-bind:isProcessing="isProcessing"
               v-bind:translationPlaceholder="translations['text-downloading-the-copy']"
               @onCopy="onChartCopy"/>
    </span>

    <WatermarkSign/>
    <CopyRightsRef v-bind:copyRightRef="translations['text-source']"/>
  </div>
</template>


<script>
import WatermarkSign from '@/shared/components/WatermarkSign/WatermarkSign';
import CopyBtn from '@/shared/components/CopyBtn/CopyBtn';
import CopyRightsRef from "@/shared/components/CopyRightsRef/CopyRightsRef";
import DistributionChartLoader from "@/shared/components/DistributionChartLoader/DistributionChartLoader.ce";
import {
  copyChart,
  generateDistributionChartLabels,
  generateTypeDistributionChart, getChartTranslations,
  getTypeDistributionChartData
} from '@/service';


export default {
  name: 'ConzoomTypeDistributionComponent',
  props: {
    api: String,
    token: String,
    lang: String,
    country: String,
    group: String
  },
  components: {CopyBtn, WatermarkSign, CopyRightsRef, DistributionChartLoader},
  async mounted() {
    this.animateChartLoader();
    await this.setChartData();
  },
  beforeUnmount() {
    this.subscriptions.forEach((subscription) => clearTimeout(subscription));
  },
  methods: {
    onChartCopy() {
      this.isProcessing = true;
      copyChart(this.$refs.chartPie, document.querySelector('conzoom-type-distribution').shadowRoot.styleSheets)
          .then(() => this.showCopiedTooltip());
    },
    showCopiedTooltip() {
      const timer = setTimeout(() => {
        this.isProcessing = false;
      }, 3000);

      this.subscriptions.push(timer);
    },
    animateChartLoader() {
      const subscription = setInterval(() => {
        this.chartLoaderValue += 1;

        if (100 === this.chartLoaderValue) {
          clearInterval(subscription);
        }
      }, 10);
    },
    async setChartData() {
      Promise.all([
          getTypeDistributionChartData(this.api, this.token, this.group),
        await getChartTranslations(this.api, this.token, this.lang, 'conzoom-type-distribution')])
          .then(([distChartData, distCharTranslation]) => {
            this.translations = distCharTranslation.reduce((acc, transl) => ({...acc, [transl.key]: transl.value}), {});
            this.distChartData = distChartData.data[0].conzoom_types
                .map((type) => ({title: type.segment, color: type.color, percent: +type.group_percent}));

            generateTypeDistributionChart({...this.chartOptions, el: this.$refs.chart, segments: this.distChartData}); //use this function to draw pie
            this.labelCoordinates = generateDistributionChartLabels(this.distChartData);
          });
    }
  },
  data: () => {
    return {
      distChartData: null,
      chartLoaderValue: 0,
      translations: {},
      chartOptions: {
        radius: 100,
      },
      labelCoordinates: [],
      isProcessing: false,
      subscriptions: [],
    };
  }
};
</script>

<style lang="scss" src="./styles.scss"></style>
