import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "switcher"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.languages.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.toggleSwitcher())),
          class: "default-lang"
        }, _toDisplayString(_ctx.selectedLanguage?.label), 1),
        (_ctx.isSwitcherShown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (lang) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: lang,
                  onClick: ($event: any) => ($options.selectLang(lang)),
                  class: "switcher-option"
                }, [
                  _createElementVNode("span", null, _toDisplayString(lang.label), 1)
                ], 8, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}