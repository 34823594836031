
export default {
  name: 'ConzoomLanguageSwitcher',
  mounted() {
    this.setLanguages();
    this.setDefaultLanguage();
    this.setDefaultLanguageFirstInArr();
    this.setClickOutsideListener();
  },
  methods: {
    selectLang(language): void {
      if (this.selectedLanguage.lang === language.lang) {
        this.isSwitcherShown = false;
        return;
      }

      window.location.href = language.link;
    },
    setLanguages(): void {
      const linkArray = document.querySelectorAll('[rel=\'alternate\']');

      this.languages = Array.from(linkArray)
      .filter((linkTag) => linkTag.getAttribute('hreflang') !== 'x-default')
      .map((linkTag) => {
        return {
          lang: linkTag.getAttribute('hreflang'),
          label: this.languagesMap[linkTag.getAttribute('hreflang') as string],
          link: linkTag.getAttribute('href'),
        };
      });

      if(!this.languages.length) console.error('There are not presented language options on the page');
    },
    setDefaultLanguage(): void {
      const defaultLang = document.getElementsByTagName('html')[0].getAttribute('lang') as string;
      this.selectedLanguage = this.languages.find((lang) => lang.lang === defaultLang.split('-')[0]);
    },
    setDefaultLanguageFirstInArr(): void {
      const selectedLangArr = this.languages.filter((lang) => lang.lang === this.selectedLanguage.lang);
      this.languages = this.languages.filter((lang) => lang.lang !== this.selectedLanguage.lang);
      this.languages = [...selectedLangArr, ...this.languages];
    },
    toggleSwitcher(): void {
      this.isSwitcherShown = true;
    },
    setClickOutsideListener(): void {
      this.selfEl = document.getElementsByTagName('conzoom-language-switcher')[0];

      document.addEventListener('click', (event) => {
        if (this.isSwitcherShown && this.selfEl !== event.target) {
          this.isSwitcherShown = false;
        }
      });
    }
  },
  data: () => {
    return {
      languages: [],
      selectedLanguage: null,
      isSwitcherShown: false,
      languagesMap: {
        da: 'Dansk',
        en: 'English',
        fi: 'Suomi',
        no: 'Norsk',
        se: 'Svenska',
      },
      selfEl: null,
    };
  }
};
