<template>
  <div class="copy-rights-ref" hidden>
    <span v-html="copyRightRef"></span>
  </div>
</template>


<script>
export default {
  name: "CopyRightsRef",
  props: {
    copyRightRef: String
  }
}
</script>
