<template>
  <div class="switcher">
    <div>
      <svg v-if="viewMode === 'percentage'"
           width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H30V30H4C1.79086 30 0 28.2091 0 26V4Z" fill="#E8E8E8"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.94118 7H7V23H7.94118V7ZM23 13H9V17H23V13ZM9 18H17V22H9V18ZM20 8H9V12H20V8Z" fill="black"/>
      </svg>

      <svg v-if="viewMode === 'index'"
           width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H30V30H4C1.79086 30 0 28.2091 0 26V4Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.94118 7H7V23H7.94118V7ZM23 13H9V17H23V13ZM9 18H17V22H9V18ZM20 8H9V12H20V8Z" fill="#B8B8B8"/>
      </svg>
    </div>

    <div>
      <svg v-if="viewMode === 'percentage'"
           width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H26C28.2091 0 30 1.79086 30 4V26C30 28.2091 28.2091 30 26 30H0V0Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15 7H14V23H15V7ZM23 13H16V17H23V13ZM7 18H13V22H7V18ZM20 8H16V12H20V8Z" fill="#B8B8B8"/>
      </svg>

      <svg v-if="viewMode === 'index'"
           width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H26C28.2091 0 30 1.79086 30 4V26C30 28.2091 28.2091 30 26 30H0V0Z" fill="#E8E8E8"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15 7H14V23H15V7ZM23 13H16V17H23V13ZM7 18H13V22H7V18ZM20 8H16V12H20V8Z" fill="black"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarChartSwitcher",
  mounted() {
  },
  props: {
    viewMode: String,
  },
}
</script>
