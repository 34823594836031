<template>
  <div class="compass-chart" ref="compassChart">
    <div class="svg-container">
      <div v-if="compassChartData?.data[0]"
           :style="{color: compassChartData?.endColor}"
           class="label-top opacity">
        {{ compassChartData?.data[0].label }}
      </div>

      <div class="horizontal-label-wrapper">
        <div v-if="compassChartData?.data[2]"
             :style="{color: compassChartData?.endColor}"
             class="opacity">
          {{ compassChartData?.data[2].label }}
        </div>

        <div v-if="compassChartData?.data[6]"
             :style="{color: compassChartData?.endColor}"
             class="opacity">
          {{ compassChartData?.data[6].label }}
        </div>
      </div>

      <svg viewBox="0 0 600 600" class="svg-content">
        <defs>
          <linearGradient id="grad">
            <stop offset="0" :style="{'stop-color': compassChartData?.startColor}"/>
            <stop offset=".5" :style="{'stop-color': color}"/>
            <stop offset="1" :style="{'stop-color': compassChartData?.endColor}"/>
          </linearGradient>
        </defs>

        <g class="compass">
          <polygon style="" points="480,480 300,560 120,480 40,300 118,120 300,40 300,40 480,120 560,300"></polygon>
          <polygon style="" points="480,480 300,560 120,480 40,300 118,120 300,40 300,40 480,120 560,300"></polygon>
          <polygon style="" points="480,480 300,560 120,480 40,300 118,120 300,40 300,40 480,120 560,300"></polygon>
          <polygon style="" points="480,480 300,560 120,480 40,300 118,120 300,40 300,40 480,120 560,300"></polygon>

          <!--  point 1 -->
          <line x1="300" y1="40" x2="300" y2="300"></line>
          <!--  point 2 -->
          <line x1="480" y1="120" x2="300" y2="300"></line>
          <!--  point 3 -->
          <line x1="300" y1="300" x2="560" y2="300"></line>
          <!--  point 4 -->
          <line x1="480" y1="480" x2="300" y2="300"></line>
          <!--  point 5 -->
          <line x1="300" y1="300" x2="300" y2="560"></line>
          <!--  point 6 -->
          <line x1="120" y1="480" x2="300" y2="300"></line>
          <!--  point 7 -->
          <line x1="40" y1="300" x2="300" y2="300"></line>
          <!--  point 8 -->
          <line x1="120" y1="120" x2="300" y2="300"></line>
        </g>

        <g class="results" ref="compassChartResults"></g>
      </svg>

      <div v-if="compassChartData?.data[4]"
           :style="{color: compassChartData?.endColor}"
           class="label-bottom opacity">
        {{ compassChartData?.data[4].label }}
      </div>
    </div>

    <span class="btn-panel">
       <CopyBtn v-bind:isProcessing="isProcessing"
                v-bind:translationPlaceholder="translations['text-downloading-the-copy']"
                @onCopy="onChartCopy"/>
    </span>

    <WatermarkSign/>
    <CopyRightsRef v-bind:copyRightRef="translations['text-source']"/>
  </div>
</template>

<script>

import CopyBtn from "@/shared/components/CopyBtn/CopyBtn";
import WatermarkSign from "@/shared/components/WatermarkSign/WatermarkSign";
import CopyRightsRef from "@/shared/components/CopyRightsRef/CopyRightsRef";
import {
  colorLuminance,
  copyChart,
  getChartTranslations,
  getCompassData
} from "@/service";

export default {
  name: "ConzoomGalupCompassComponent",
  props: {
    api: String,
    token: String,
    lang: String,
    country: String,
    segment: String,
    color: String,
  },
  components: {CopyBtn, WatermarkSign, CopyRightsRef},
  async mounted() {
    const compassResponse = await getCompassData(this.api, this.token, this.country, this.segment);
    const rawTranslations = await getChartTranslations(this.api, this.token, this.lang, 'conzoom-galup-compass');
    this.translations = rawTranslations.reduce((acc, transl) => ({...acc, [transl.key]: transl.value}), {});
    this.startColor = colorLuminance(this.color, 0.2);
    this.endColor = colorLuminance(this.color, -0.2);
    this.compassChartData = this._getPreparedCompassData(compassResponse);
    const scores = this.compassChartData.data.reduce((acc, curr) => ({...acc, [curr.direction]: curr.value}), {});

    let finalPoints = {
      north: `300, ${this.compassChartRadius - scores.north * this.compassChartPolygonStep + 40}`,
      northEast: `${480 - ((480 - 300) / this.maxScores) * (this.maxScores - scores.northEast)}, ${120 + ((480 - 300) / this.maxScores) * (this.maxScores - scores.northEast)}`,
      east: `${560 - (this.maxScores - scores.east) * this.compassChartPolygonStep}, 300`,
      southEast: `${480 - ((480 - 300) / this.maxScores) * (this.maxScores - scores.southEast)}, ${480 - ((480 - 300) / this.maxScores) * (this.maxScores - scores.southEast)}`,
      south: `300, ${560 - ((560 - 300) / this.maxScores) * (this.maxScores - scores.south)}`,
      southWest: `${((300 - 120) / this.maxScores * (26 - scores.southWest)) + 120}, ${480 - ((300 - 120) / this.maxScores * (26 - scores.southWest))}`,
      west: `${this.compassChartRadius - scores.west * this.compassChartPolygonStep + 40}, 300`,
      northWest: `${120 + ((300 - 120) / this.maxScores) * (this.maxScores - scores.northWest)}, ${120 + ((300 - 120) / this.maxScores) * (this.maxScores - scores.northWest)}`,
    };

    this.$refs.compassChartResults.innerHTML = `
    <polygon class="scale-animation"
    points="${finalPoints.north}
            ${finalPoints.northWest}
            ${finalPoints.west}
            ${finalPoints.southWest}
            ${finalPoints.south}
            ${finalPoints.southEast}
            ${finalPoints.east}
            ${finalPoints.northEast}"/>`;
  },
  beforeUnmount() {
    this.subscriptions.forEach((subscription) => clearTimeout(subscription));
  },
  methods: {
    onChartCopy() {
      this.isProcessing = true;

      copyChart(this.$refs.compassChart, document.querySelector('conzoom-galup-compass').shadowRoot.styleSheets)
          .then(() => this.showCopiedTooltip());
    },
    showCopiedTooltip() {
      const timer = setTimeout(() => {
        this.isProcessing = false;
      }, 3000);

      this.subscriptions.push(timer);
    },
    _getPreparedCompassData(compassResponse) {
      const compassDirections = ['north', 'northEast', 'east', 'southEast', 'south', 'southWest', 'west', 'northWest'];
      const compassData = compassResponse.data[0].variable.conzoom_data.map((option, i) => {
        const translation = option.label.translations.find((transl) => transl.lang === this.lang);

        return {label: translation.value, value: +option[this.segment.toLowerCase()], direction: compassDirections[i]};
      });

      return {startColor: this.startColor, endColor: this.endColor, data: compassData}
    }
  },
  data: () => {
    return {
      isProcessing: false,
      compassChartData: null,
      compassChartRadius: 260,
      compassChartPolygonStep: 10,
      maxScores: 26,
      subscriptions: [],
      startColor: '',
      endColor: '',
      translations: {},
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
